import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="text-section">
      <h2>PAGE NOT FOUND</h2>
      <br />
      <p>
        The page you are looking for does not exist...{" "}
        <span role="img" aria-label="crying face">
          😭
        </span>
      </p>
    </section>
  </Layout>
)

export default NotFoundPage
